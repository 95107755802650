<template>

    <a-card title="我的帖子">
        <template v-for="postVO in dataList">
            <PostCard :PostVO="postVO"></PostCard>
        </template>
    </a-card>
</template>

<script setup lang="ts">
    import PostCard from '@/components/PostCard'
    import {ref,onMounted} from 'vue'
    import {
        addPostUsingPost,
        listMyPostVoByPageUsingPost,
        listPostByPageUsingPost,
        listPostVoByPageUsingPost
    } from "@/api/postController";
    import PostVO = API.PostVO;
    import message from "@arco-design/web-vue/es/message";
    import { IconPlus, IconDelete } from '@arco-design/web-vue/es/icon';
    import {useStore} from "vuex";
    import {addTeamUsingPost} from "@/api/teamController";
    import MdEditor from "@/components/MdEditor.vue";

    const dataList = ref<PostVO[]>([])
    const loadData = async () =>{
        const res = await listMyPostVoByPageUsingPost({});
        if(res.data.code === 0){
            dataList.value = res.data.data?.records || [];
        }else{
            message.error("获取失败");
        }
    }

    onMounted(() =>{
        loadData();
    })

    //帖子内容
    const content = ref('');
    //梯子标题
    const title = ref('');

    //同步输入的内容
    const doWriteContent = (value:string) =>{
        content.value = value
    }



</script>

<style scoped>

</style>