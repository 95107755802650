<template>
  <div id="teamManager">
    <a-form :model="searchParam" layout="inline">
      <a-form-item field="name" label="队伍名称" style="min-width: 240px">
        <a-input v-model="searchParam.name" placeholder="请输入名称" />
      </a-form-item>
      <a-form-item field="description" label="队伍描述" style="min-width: 240px">
        <a-input-tag v-model="searchParam.description" placeholder="请输入队伍描述" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="doSubmit">搜索</a-button>
      </a-form-item>
    </a-form>

    <a-table :columns="columns" :data="dataList"
             :pagination="{
                    showTail: true,
                    pageSize: searchParam.pageSize,
                    current: searchParam.current,
                    total
                 }"
             @page-change="onPageChange"
    >
        <template #teamIcon="{ record }">
            <a-image width="50" :src="record.teamIcon ?? myAppIcon"></a-image>
        </template>
      <template #expireTime="{ record }">
        {{ dayjs(record.expireTime).format("YYYY-MM-DD HH:mm:ss") }}
      </template>
        <template #maxNum="{ record }">
        <a-tag color="red">{{record.maxNum}}</a-tag>
      </template>
        <template #joinNum="{ record }" >
            <a-avatar-group>
                <a-avatar v-for="user in record.joinUserList" :key="user.id" :image-url="user.userAvatar ?? myAvatar" size="20px"></a-avatar>
            </a-avatar-group>
      </template>

      <template #optional="{ record }">
        <a-space size="large">
            <a-button type="primary"  status="danger" @click="doDelete(record.id)">删除</a-button>
            <a-button type="primary"  @click="doEdit(record.id)">修改</a-button>
        </a-space>
      </template>
    </a-table>
      <a-modal v-model:visible="visible" @ok="handleOk">
          <template #title>
              创建队伍
          </template>
          <a-form :model="form">
              <a-form-item field="name" label="队伍名称">
                  <a-input v-model="form.name" placeholder="请输入队伍名称"/>
              </a-form-item>
              <a-form-item field="description" label="队伍描述">
                  <a-input v-model="form.description" placeholder="请输入队伍描述" />
              </a-form-item>
              <a-form-item label="最大人数" field="step">
                  <a-input-number :style="{ width: '100px' }" v-model="form.maxNum" />
              </a-form-item >
              <a-form-item label="过期时间" field="expireTime">
                  <a-date-picker v-model="form.expireTime" style="width: 200px;" />
              </a-form-item>
              <a-form-item label="状态" field="status">
                  <a-radio-group v-model="form.status">
                      <a-radio value="0">公开</a-radio>
                      <a-radio value="1">私有</a-radio>
                      <a-radio value="2">加密</a-radio>
                  </a-radio-group>
              </a-form-item>
          </a-form>
      </a-modal>
  </div>
</template>

<script setup lang="ts">
  import {ref,onMounted,watchEffect} from "vue";
  import {
    deleteQuestionUsingPost,
    listQuestionByPageUsingPost,
    listQuestionVoByPageUsingPost
  } from "@/api/questionController";
  import message from "@arco-design/web-vue/es/message"
  import { dayjs } from "@arco-design/web-vue/es/_utils/date";
  import {useRouter} from "vue-router";
  import {
      addTeamUsingPost, getTeamByIdUsingGet,
      getTeamListPageUsingGet,
      joinTeamUsingPost, leaderQuitTeamUsingPost, updateUsingPost, uploadTeamIconUsingPost
  } from "@/api/teamController";
  import { IconPlus, IconDelete } from '@arco-design/web-vue/es/icon';
  import TeamAddRequest = API.TeamAddRequest;
  import {useStore} from "vuex";
  import TeamUserVO = API.TeamUserVO;
  import {FileItem} from "@arco-design/web-vue";
  import {uploadUserAvatarUsingPost} from "@/api/userController";
  import {myAppIcon, myAvatar} from "@/constant/photo";
  import TeamUpdateRequest = API.TeamUpdateRequest;

  const router = useRouter();
  const show = ref(true)

  const columns = [
    {
      title: "队伍id",
      dataIndex: "id",
    },
    {
        title: "队伍图标",
        dataIndex: "teamIcon",
        slotName: "teamIcon"
    },
    {
      title: "队伍名称",
      dataIndex: "name",
    },
    {
      title: "队伍描述",
      dataIndex: "description",
    },
    {
      title: "最大人数",
      dataIndex: "maxNum",
      slotName: "maxNum"
    },
      {
      title: "当前人数",
      dataIndex: "joinNum",
      slotName: "joinNum"
    },
    {
      title: "过期时间",
      dataIndex: "expireTime",
      slotName: "expireTime"
    },
    {
      slotName: "optional",
    },
  ];

  //获取表格数据的请求参数
  // 初始化搜索条件（不应该被修改）
  const initSearchParam = ref<API.TeamQueryRequest>({
    name:'',
    description:'',
    pageSize:10,
    current:1
  })
  const searchParam = ref({
    ...initSearchParam.value
  })
  //定义总条数
  const total = ref<number>(0)
  const dataList = ref<API.TeamUserVO[]>([]);
  const teamIcon = ref('')

  const loadData = async () =>{
    const res = await getTeamListPageUsingGet(searchParam.value);
    if(res.data.code === 0){
      dataList.value = res.data.data?.records || [];
      total.value = res.data.data?.total || 0;
    }else{
      message.error("获取信息失败"+res.data.message);
    }
  }


  //改变页数
  //改页数事件
  const onPageChange = (page:number) =>{
    searchParam.value = {
      ...searchParam.value,
      current:page
    }
  }

  /**
   * 监听 searchParams 变量，改变时触发数据的重新加载
   */
  watchEffect(() =>{
    loadData();
  })

  /**
   * 执行搜索按钮
   */
  const doSubmit = () =>{
    //重新将当前页面置为1
    searchParam.value = {
      ...searchParam.value,
      current:1
    }
    loadData();
  }


  const form = ref<API.TeamUpdateRequest>({
      name:'',
      description:'',
      maxNum: 0,
      expireTime:"",
      status:0
  })
  const visible = ref(false);

  const store = useStore();
  const doDelete = async (id:number) =>{
      const res = await leaderQuitTeamUsingPost({
          teamId:id
      })
      if(res.data.code === 0){
          message.success("删除成功")
      }else{
          message.error("删除失败")
      }
  }

  const teamId = ref();
  const doEdit = async (id:number) =>{
      visible.value = true;
      teamId.value = id;
      const res = await getTeamByIdUsingGet({
          id:id
      });
      if(res.data.code === 0){
          form.value = res.data.data as any
      }
  }
//86358868
  const handleOk = async () =>{
      const res = await updateUsingPost({
          id:teamId.value,
          name:form.value.name,
          description:form.value.description,
          expireTime:form.value.expireTime,
          maxNum:form.value.maxNum,
          status:form.value.status,
      });
      if(res.data.code === 0){
          message.success("更新成功")
      }else{
          message.error("更新失败")
      }
  }

</script>

<style scoped>
  #teamManager {
    max-width: 1280px;
    margin:0 auto;
  }
</style>
