<template>

    <a-card title="我的好友">
        <a-space style="margin-bottom: 30px">
            <icon-unordered-list size="32" @click="doFriends"/>
            <icon-message size="32" style="margin-left: 1050px" @click="doMessage"/>
        </a-space>
        <template v-if="dataList === []">
            暂无好友<a-empty/>
        </template>
        <template v-for="userVO in dataList" >
            <UserCard :userVO="userVO" @click="SendMessage(userVO.id)"></UserCard>
        </template>

    </a-card>

    <!--  查看消息  -->
    <template>
        <a-modal v-model:visible="visible" @ok="handleOk" @cancel="handleCancel" hide-cancel="true" fullscreen >
            <template #title>
                我的消息
            </template>
            <template v-if="messageList.length === 0">
                <a-empty></a-empty>
            </template>

            <template v-for="message in messageList">
                <MessageCard :MessageVO="message"></MessageCard>
            </template>
        </a-modal>
    </template>

    <template>
        <a-modal v-model:visible="visible2" @ok="handleOk" @cancel="handleCancel">
            <template #title>
                好友管理
            </template>
            <div>You can customize modal body text by the current situation. This modal will be closed immediately once you press the OK button.</div>
        </a-modal>
    </template>

    <template>
        <a-modal v-model:visible="visible3" @ok="handleSendMessage" @cancel="handleCancel" ok-text="发送">
            <template #title>
                留言
            </template>
            <a-textarea v-model="context" placeholder="Please enter something" allow-clear/>
        </a-modal>
    </template>

</template>

<script setup lang="ts">
    import UserCard from '@/components/UserCard'
    import {ref,onMounted} from 'vue'
    import {
        addPostUsingPost,
        listMyPostVoByPageUsingPost,
        listPostByPageUsingPost,
        listPostVoByPageUsingPost
    } from "@/api/postController";
    import PostVO = API.PostVO;
    import message from "@arco-design/web-vue/es/message";
    import { IconPlus, IconDelete } from '@arco-design/web-vue/es/icon';
    import {useStore} from "vuex";
    import {addTeamUsingPost} from "@/api/teamController";
    import MdEditor from "@/components/MdEditor.vue";
    import {showMyFriendsUsingGet} from "@/api/userController";
    import {getMyMessageUsingGet, sendMessageUsingPost} from "@/api/friendsController";
    import PostCard from "@/components/PostCard.vue";
    import MessageCard from "@/components/MessageCard.vue";
    import store from "@/store";

    const dataList = ref<API.UserVO[]>([])
    const loadData = async () =>{
        const res = await showMyFriendsUsingGet({});
        if(res.data.code === 0){
            dataList.value = res.data.data || [];
        }else{
            message.error("获取失败");
        }
    }

    onMounted(() =>{
        loadData();
    })

    const visible = ref(false);
    const visible2 = ref(false);

    const handleOk = () => {
        visible.value = false;
        visible2.value = false
    };
    const handleCancel = () => {
        visible.value = false;
        visible2.value = false;
    }

    const messageList = ref<API.MessageVO[]>([]);
    const doMessage = async () =>{
        visible.value = true;
        const res  = await getMyMessageUsingGet();
        if(res.data.code === 0){
            messageList.value = res.data.data || [] as any
        }else{
            message.error("获取信息失败" + res.data.message);
        }
    }

    const doFriends = () =>{
        visible2.value = true;
    }

    const visible3 = ref(false)
    const context = ref('')
    const toId = ref()
    const SendMessage = (id:number) =>{
        toId.value = id
        visible3.value = true
    }

    //发送消息
    const handleSendMessage = async () =>{
        const res = await sendMessageUsingPost({
            fromId:store.state.user.loginUser.id,
            receiveId:toId.value,
            message:context.value
        })
        if(res.data.code === 0){
            message.success("发送成功")
        }else{
            message.error("发送失败")
        }
    }


</script>

<style scoped>

</style>