<template>
    <div id="UserManager">
        <a-table :columns="columns" :data="dataList"
                 :pagination="{
                    showTail: true,
                    pageSize: searchParam.pageSize,
                    current: searchParam.current,
                    total
                 }"
                 @page-change="onPageChange"
        >
            <template #optional="{ record }">
                <a-space>
                    <a-button status="danger"  @click="doDelete(record)">删除</a-button>
                </a-space>
            </template>
            <template #ban="{ record }">
                <a-space>
                    <a-switch v-if="record.userRole === 'ban'" checked-color="green" unchecked-color="red" @change="doChange(record.id)"/>
                    <a-switch v-else checked-color="red" unchecked-color="green" @change="doChange(record.id)"/>
                </a-space>
            </template>
            <template #userRole="{ record }">
                <a-tag v-if="record.userRole === 'user'" color="green">普通用户</a-tag>
                <a-tag v-if="record.userRole === 'role'" color="orange">管理员</a-tag>
                <a-tag v-if="record.userRole === 'ban'" color="red">封号</a-tag>
            </template>
            <template #userName="{ record }">
                {{record.userName ?? '无名'}}
            </template>
            <template #userProfile="{ record }">
                {{record.userProfile ?? '暂无'}}
            </template>
            <template #tags="{ record }">
                {{record.tags ?? '[]'}}
            </template>
            <template #userAvatar="{ record }">
                <a-image :src="record.userAvatar ?? myAvatar" width="50"/>
            </template>
        </a-table>
    </div>
</template>

<script setup lang="ts">
import {ref,onMounted,watchEffect} from "vue";
import {deleteQuestionUsingPost, listQuestionByPageUsingPost} from "@/api/questionController";
import message from "@arco-design/web-vue/es/message"
import { dayjs } from "@arco-design/web-vue/es/_utils/date";
import {useRouter} from "vue-router";
import {bandUserUsingPost, listUserVoByPageUsingPost} from "@/api/userController";
import {myAvatar} from "@/constant/photo";

const router = useRouter();
const show = ref(true)

const columns = [
    {
        title: "id",
        dataIndex: "id",
    },
    {
        title: "账号",
        dataIndex: "userAccount",
    },
    {
        title: "头像",
        dataIndex: "userAvatar",
        slotName: "userAvatar"
    },
    {
        title: "用户昵称",
        dataIndex: "userName",
        slotName: "userName"
    },
    {
        title: "用户简介",
        dataIndex: "userProfile",
        slotName: "userProfile"
    },
    {
        title: "标签列表",
        dataIndex: "tags",
    },
    {
        title: "角色",
        dataIndex: "userRole",
        slotName: "userRole"
    },
    {
        title: "删除",
        slotName: "optional",
    },
    {
        title: "封号",
        slotName: "ban",
    },
];

//获取表格数据的请求参数
// 初始化搜索条件（不应该被修改）
const initSearchParam = ref<API.UserQueryRequest>({
    pageSize:10,
    current:1
})
const searchParam = ref({
    ...initSearchParam.value
})
//定义总条数
const total = ref<number>(0)
const dataList = ref<API.Question[]>([]);
const loadData = async () =>{
    const res = await listUserVoByPageUsingPost(searchParam.value);
    if(res.data.code === 0){
        dataList.value = res.data.data?.records || [];
        total.value = res.data.data?.total || 0;
    }else{
        message.error("获取信息失败"+res.data.message);
    }
}

//删除方法
const doDelete = async (record:API.Question) =>{
    const res = await deleteQuestionUsingPost({id:record.id});
    if(res.data.code === 0){
        message.success("删除成功!")
        loadData()
    }else{
        message.error("删除失败!")
    }
}

//更新方法
const doUpdate = (record:API.Question) =>{
    router.push({
        path:"/update/question",
        query:{
            id:record.id
        }
    })
}

//改变页数
//改页数事件
const onPageChange = (page:number) =>{
    searchParam.value = {
        ...searchParam.value,
        current:page
    }
}

/**
 * 监听 searchParams 变量，改变时触发数据的重新加载
 */
watchEffect(() =>{
    loadData()
})

const doChange = async (id:number) =>{
    const res = await bandUserUsingPost(id);
    if(res.data.code === 0){
        message.success("操作成功")
        window.location.reload()
    }else{
        message.error("操作失败")
    }
}
</script>

<style scoped>
#UserManager {
}
</style>
