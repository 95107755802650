<template>
    <div id="userPage">
        <!--        <div>-->
        <img :alt="user.username" class="img" :src="user.userAvatar ? user.userAvatar : myAvatar ">
        <!--        </div>-->
        <a-tag checkable size="large" color="red" :default-checked="true" v-if="user.userRole === 'admin'">管理员</a-tag>
        <a-tag checkable size="large" color="green" :default-checked="true" v-else>普通用户</a-tag>

        <a-upload  :multiple="false"
                   :show-file-list="false"
                   action="/"
                   :auto-upload="false"
                   style="margin-left:510px"
                   @change="onChange"/>

        <a-form :model="form"
                label-align="left"
                auto-label-width
                :style="{ width: '600px',margin:'0 auto',marginTop:'68px'}"
                @submit="handleSubmit">
            <a-form-item field="userAccount" label="用户名">
                <a-input
                        v-model="form.userName"
                        placeholder="请输入用户名"
                />
            </a-form-item>

            <a-form-item label="简介">
                <a-input v-model="form.userProfile" placeholder="输入简介" />
            </a-form-item>


            <a-link style="margin-right: 400px;" href="/my/team" icon>我创建的队伍</a-link>
            <a-link style="margin-right: 415px" href="/my/post" icon>我发的帖子</a-link>
            <a-link style="margin-right: 425px" href="/my/friend" icon>我的好友</a-link>

            <a-form-item>
                <a-space style="margin-top:24px">
                    <a-button type="primary" status="warning" style="width: 120px;margin-right: 50px" @click="doLogout">
                        注销
                    </a-button>
                    <a-button type="primary" html-type="submit" style="width: 120px;margin-left: 250px">
                        修改
                    </a-button>
                    <a-button @click="doSingin">签到</a-button>
                </a-space>
            </a-form-item>
        </a-form>
    </div>
    <v-chart :option="option"></v-chart>
</template>

<script setup lang="ts">
import {onMounted, reactive, ref, watchEffect} from "vue";
import {
    selectSignInRecordUsingGet,
    updateMyUserUsingPost, uploadUserAvatarUsingPost,
    userLogoutUsingPost,
    userSignInUsingPost
} from "@/api/userController";
    import message from "@arco-design/web-vue/es/message"
    import {useRouter} from "vue-router";
    import {myAvatar} from '@/constant/photo'
    import {useStore} from "vuex";
    import VChart from "vue-echarts";
    import "echarts";
    import CalendCard from "@/views/user/calend/CalendCard.vue";
    import dayjs from "dayjs";
import {FileItem} from "@arco-design/web-vue";
import myAxios from "@/request";

    const store = useStore();
    const user = store.state.user.loginUser
    const router = useRouter();

    const form = reactive<API.LoginUserVO>({
        userName:'',
        userProfile:'',
    });

    const loadData = () =>{
        form.userName = user.userName;
        form.userProfile = user.userProfile;
    }

    const handleSubmit = async () =>{
        const res = await updateMyUserUsingPost(form);
        if(res.data.code === 0){
            message.success("修改成功");
            // form = loginUserStore.fetchLoginUser
            window.location.reload()
        }else{
            message.error("修改失败")
        }
    }

    //注销
    const doLogout = async () =>{
        const res = await userLogoutUsingPost();
        if(res.data.code === 0){
            router.push({
                path:"/user/login",
                replace:true,
            })
        }
    }

    //获取签到记录
    const dataList = ref<number[]>([]);
    onMounted(async () =>{
        const res = await selectSignInRecordUsingGet();
        if(res.data.code === 0){
            dataList.value = res.data.data as [] || [];
        }else {
            message.error("获取签到记录失败")
        }
    })

    //todo
    const year = new Date().getFullYear();
    const optionData = dataList.value.map((dayOfYear) => {
        console.log("aaaa")
        const dateStr = dayjs(`${year}-01-01`)
            .add(dayOfYear - 1, "day")
            .format("YYYY-MM-DD");
        console.log("日期aaaa"+dateStr)
        return [dateStr, 1];
    });

    const option = {
        visualMap: {
            show: false,
            min: 0,
            max: 1,
            inRange: {
                // 颜色从灰色到浅绿色
                color: ["#efefef", "lightgreen"],
            },
        },
        calendar: {
            range: year,
            left: 20,
            cellSize: ['auto', 16],
            yearLabel: {
                position: "top",
                formatter: `${year}签到记录`
            }
        },
        series: {
            type: 'heatmap',
            coordinateSystem: 'calendar',
            data: optionData
        }
    };

    watchEffect(() =>{
        loadData()
    })

    //签到
    const doSingin = async () =>{
        const res = await userSignInUsingPost();
        if(res.data.code === 0){
            message.success("签到成功");
            window.location.reload();
        }else{
            message.error("签到失败")
        }
    }

    const formData = new FormData();

    // const onChange = async (_: any, currentFile: FileItem) =>{
    //     formData.append("file",currentFile.file as Blob)
    //     // const res = await myAxios.post("/api/user/upload",formData);
    //     const res = await myAxios.post("/api/user/upload",{
    //         formData,
    //         biz:"user_avatar"
    //     });
    //     if(res.data.code === 0){
    //         message.success("上传成功")
    //     }else{
    //         message.error("上传失败")
    //     }
    // }

    const onChange = async (_: any, currentFile: FileItem) =>{
        // const param = ref<API.uploadUserAvatarUsingPOSTParams>();
        const uploadUserAvatarUsingPOSTParams = ref({
            biz:"user_avatar"
        })
        const res = await uploadUserAvatarUsingPost(uploadUserAvatarUsingPOSTParams.value,{},currentFile?.file);
        if(res.data.code === 0){
            message.success("上传成功")
            window.location.reload()
        }else{
            message.error("上传失败")
        }
    }

</script>

<style scoped>
    .img{
        box-shadow: 0 0 14px rgba(0, 0, 0, 0.5);
        border-radius: 20%;
        height: 135px;
        width: 135px;
        margin-left:500px;
    }
</style>